<template>
    <div v-if="!!statistics">
        <div class="columns my-statistics">
            <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Active Sellers"
                    :count="statistics.active_sellers || 0"
                    :link="{ name: 'user.list', query: { filter_on: 'status', filter: 'active' } }"
                    glyph="home"
                    color="dark"
                />
            </div>
            <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Inactive Sellers"
                    :count="statistics.inactive_sellers || 0"
                    :link="{ name: 'user.list', query: { filter_on: 'status', filter: 'inactive' } }"
                    glyph="home"
                    color="gray"
                />
            </div>
            <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Active Listings"
                    :count="statistics.total_active_listings || 0"
                    link="#active"
                    glyph="sign"
                    color="dark"
                    @click="$emit('click', $event)"
                />
            </div>
            <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Sales"
                    :count="statistics.total_sales || 0"
                    link="#closed"
                    glyph="sign"
                    color="primary"
                    @click="$emit('click', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { handles_errors } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import { CountedStatistic } from "@/custom/components"

export default {
    name: "MyStatistics",
    mixins: [addl_profile_utilities, handles_errors],
    components: {
        CountedStatistic
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh"
    },
    methods: {
        refresh () {
            if(!this.profile_id) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "user",
                    action: "statistics",
                    method: "get",
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        }
    },
    computed: {
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.my-statistics {
    .latest-entry {
        font-size: $font-size-sm;
        color: $gray-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
