<template>
    <top-listings
        :title="title"
        :sub-title="sub_title"
        :records="records"
    />
</template>

<script>
import { TopListings } from "@/custom/components"

export default {
    name: "MyTopList",
    components: {
        TopListings
    },
    computed: {
        records () {
            return this.statistics[`${this.prefix}_${this.direction}_by_${this.field}`]
        },
        sub_title () {
            return `By ${this.field.charAt(0).toUpperCase() + this.field.slice(1)}`
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        statistics: {
            type: Object,
            required: true
        },
        field: {
            type: String,
            required: true
        },
        prefix: {
            type: String,
            required: true
        },
        direction: {
            type: String,
            validator: prop => !!prop && prop.match(/^top|bottom()$/),
            default: "top"
        }
    }
}
</script>
