<template>
    <div v-if="!!statistics">
        <div class="columns my-statistics">
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <counted-statistic
                    title="My Sold Listings (YTD)"
                    :count="statistics.sales_ytd || 0"
                >
                    <img src="@/assets/img/logo.png" :alt="translate('My Sold Listings (YTD)')" />
                </counted-statistic>
            </div>
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <counted-statistic
                    title="My Taken Listings (YTD)"
                    :count="statistics.taken_ytd || 0"
                >
                    <img src="@/assets/img/logo.png" :alt="translate('My Taken Listings (YTD)')" />
                </counted-statistic>
            </div>
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <counted-statistic
                    title="MLS Sold Listings (YTD)"
                    :count="statistics.mls_sales_ytd || 0"
                    glyph="sign"
                    color="primary"
                />
            </div>
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Active MLS Single Family Listings"
                    :count="statistics.mls_single_family_total || 0"
                    glyph="sign"
                    color="primary"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { handles_errors } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import { CountedStatistic } from "@/custom/components"

export default {
    name: "MySales",
    mixins: [addl_profile_utilities, handles_errors],
    components: {
        CountedStatistic
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh"
    },
    methods: {
        refresh () {
            if(!this.profile_id) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "user",
                    action: "sales",
                    method: "get",
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        }
    },
    computed: {
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.my-sales {
}
</style>
