<template>
    <page
        id="listings-my-list"
        :title="translate('My Listings')"
        :status="page_status"
        :is-root="true"
        :hash-scroll-timeout="0"
        @statused="statused"
    >
        <my-statistics
            class="mb-4"
            @click="navigate"
        />

        <my-top-lists
            class="mb-8"
        />

        <my-sales
            class="mb-8"
        />

        <showings-list
            :listing="{ id: 0 }"
            :feedback-min-date="$dayjs().subtract(7, 'days')"
            class="mb-8"
            @loaded="showings_data_version++"
        />

        <my-historical-trends
            :showings-data-version="showings_data_version"
            class="mb-8"
        />

        <user-listings
            ref="listings_list"
            class="nibnut-scroll-target"
            @loaded="center = $event"
        />
        <!--
        <section
            ref="listings_list"
            id="my-active-listings"
            class="card nibnut-scroll-target"
        >
            <div class="card-header">
                <div class="card-title h5">
                    <div class="columns">
                        <div class="column col-9 col-lg-8 col-md-6 col-sm-12">
                            <form-dropdown
                                id="status_id"
                                name="status_id"
                                :value="state.filter"
                                :options="property_status"
                                :required="true"
                                @input="filter_by_status"
                            />
                        </div>
                        <div class="column col-3 col-lg-4 col-md-6 col-sm-12">
                            <list-mode-picker
                                :list-mode="list_mode"
                                size="sm"
                                @input="set_list_mode"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <listings-list
                    :list-mode="list_mode"
                    :listings="listings"
                    :state="state"
                    :data-version="data_version"
                    :loading="loading"
                    :can-add="is_at_least_realtor"
                    @sort="sort_by"
                    @page="goto_page"
                />
            </div>
        </section>
        //-->
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/nibnut/mixins"

import ShowingsList from "@/custom/components/ShowingsList"
import UserListings from "@/custom/components/UserListings"
import MyStatistics from "./MyStatistics"
import MyTopLists from "./MyTopLists"
import MySales from "./MySales"
import MyHistoricalTrends from "./MyHistoricalTrends"

export default {
    mixins: [is_page],
    components: {
        ShowingsList,
        MyStatistics,
        MyTopLists,
        MySales,
        MyHistoricalTrends,
        UserListings
    },
    methods: {
        navigate ({ href }) {
            const matches = href.match(/#(.+)$/)
            if(matches) {
                const status = this.property_status.find(status => status.slug === matches[1])
                if(status) this.filter_by_status(status.id)
            }
        }
    },
    data () {
        return {
            showings_data_version: 0
        }
    }
}
</script>
