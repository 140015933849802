<template>
    <div v-if="!!statistics" class="columns my-top-lists">
        <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <my-top-list
                :title="translate('My Most Active Listings')"
                :statistics="statistics"
                field="views"
                prefix="my"
                class="mb-4"
            />
        </div>
        <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <my-top-list
                :title="translate('My Least Active Listings')"
                :statistics="statistics"
                field="views"
                direction="bottom"
                prefix="my"
                class="mb-4"
            />
        </div>
        <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <my-top-list
                :title="translate('My Most Active Listings')"
                :statistics="statistics"
                field="showings"
                prefix="my"
                class="mb-4"
            />
        </div>
        <div class="column col-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <my-top-list
                :title="translate('My Least Active Listings')"
                :statistics="statistics"
                field="showings"
                direction="bottom"
                prefix="my"
                class="mb-4"
            />
        </div>
    </div>
</template>
<script>
import { addl_profile_utilities } from "@/custom/mixins"

import MyTopList from "./MyTopList"

export default {
    name: "MyTopLists",
    mixins: [addl_profile_utilities],
    components: {
        MyTopList
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh",
        advancedQuery: "refresh"
    },
    methods: {
        refresh () {
            if(!this.profile_id) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "listing",
                    action: "activity/my-listings",
                    method: "get",
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        }
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>
