<template>
    <section v-if="is_at_least_realtor && !!statistics" class="card my-historical-trends">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("My Historical Trend") }}
                <form-dropdown
                    id="year_count"
                    name="year_count"
                    v-model="year_count"
                    :options="years"
                    :required="true"
                    class="float-right"
                    @input="refresh"
                />
            </div>
        </div>
        <div class="card-body">
            <div class="columns">
                <div class="column col-6 col-lg-6 col-md-12">
                    <div class="card pull-up">
                        <div class="card-header">
                            <h6>
                                {{ translate("Active Listings") }}
                            </h6>
                        </div>
                        <div
                            class="card-body py-0"
                            @click.prevent="toggle_zoom(translate('Active Listings'), 'active_listings')"
                        >
                            <Portal to="destination-modal-destination" :disabled="zoomed != 'active_listings'">
                                <apexchart
                                    :height="(zoomed === 'active_listings') ? 400 : 200"
                                    type="line"
                                    :options="chart_options('active_listings')"
                                    :series="chart_data('active_listings')"
                                />
                            </Portal>
                        </div>
                    </div>
                </div>
                <div class="column col-6 col-lg-6 col-md-12">
                    <div class="card pull-up">
                        <div class="card-header">
                            <h6>
                                {{ translate("Showings") }}
                            </h6>
                        </div>
                        <div
                            class="card-body py-0"
                            @click.prevent="toggle_zoom(translate('Showings'), 'showings')"
                        >
                            <Portal to="destination-modal-destination" :disabled="zoomed != 'showings'">
                                <apexchart
                                    :height="(zoomed === 'showings') ? 400 : 200"
                                    type="line"
                                    :options="chart_options('showings')"
                                    :series="chart_data('showings')"
                                />
                            </Portal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <destination-modal
            v-if="!!zoomed"
            :show.sync="zoomed"
            :title="zoom_title"
        />
    </section>
</template>

<script>
import { displays_historical_trends } from "@/custom/mixins"

export default {
    name: "MyHistoricalTrends",
    mixins: [displays_historical_trends]
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.my-historical-trends {
    @media (max-width: $size-sm) {
        .column + .column {
            margin-top: $unit-4;
        }
    }
}
</style>
